<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/tabs/help/procedures"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.procedureDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="odn-procedure-name">
        {{ procedure.name }}
      </div>
      <ion-card
        v-if="procedure.project"
        button
        :router-link="`/tabs/projects/${procedure.project.id}`"
      >
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="auto">
              <ion-thumbnail v-if="procedure.project.customer.logo">
                <img :src="procedure.project.customer.logo" />
              </ion-thumbnail>
              <ion-thumbnail v-else>
                <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
              </ion-thumbnail>
            </ion-col>
            <ion-col>
              <ion-card-subtitle>{{
                procedure.project.customer.name
              }}</ion-card-subtitle>
              <ion-card-title>{{ procedure.project.name }}</ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ODNBadges>
            <ODNBadge :color="procedure.project.projectStatus.color">
              {{
                $t(`projectStatuses.${procedure.project.projectStatus.name}`)
              }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon
                :icon="icons.ellipse"
                :style="{
                  color: procedure.project.hasSurveys ? '#2dd36f' : '#eb445a',
                }"
              />
              {{ $t('labels.hasSurveys') }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon
                :icon="icons.ellipse"
                :style="{
                  color: procedure.project.hasTickets ? '#2dd36f' : '#eb445a',
                }"
              />
              {{ $t('labels.hasTickets') }}
            </ODNBadge>
          </ODNBadges>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>
      <!-- Files -->
      <ion-list lines="none">
        <ion-item
          button
          :router-link="`/tabs/help/procedures/${procedure.id}/files`"
        >
          <ion-icon :icon="icons.documentsOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.files') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="procedure.procedureFileCount > 0 ? 'primary' : null"
          >
            {{ procedure.procedureFileCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
      </ion-list>
      <!-- Content -->
      <div v-html="procedure.content" class="odn-procedure-content"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonThumbnail,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonSpinner,
  toastController,
} from '@ionic/vue';
import { ellipse, documentsOutline } from 'ionicons/icons';

import ODNBadges from '@c/odn-badges.vue';
import ODNBadge from '@c/odn-badge.vue';

import APIService from '@s/api.service';

export default {
  name: 'ProcedureDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonThumbnail,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonCardHeader,
    IonRow,
    IonCol,
    IonRippleEffect,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonSpinner,
    ODNBadges,
    ODNBadge,
  },
  computed: {
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  data() {
    return {
      procedure: {},
      loading: true,
      icons: {
        ellipse,
        documentsOutline,
      },
    };
  },
  ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const response = await APIService.get(
          `/procedures/${this.$route.params.id}`,
        );
        this.procedure = response.data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.procedure.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.odn-procedure {
  &-name {
    padding: 16px 16px 8px 16px;
    font-size: 20px;
  }
  &-content {
    padding: 0 16px;
  }
}
</style>
